import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Setting, SettingError } from '../models/Setting.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  private HttpClient = inject(HttpClient);
  private routes = environment.routes
  constructor() { }

  public getMaintenanceStatus(): Observable<Setting | SettingError> {
    return this.HttpClient.get<Setting | SettingError>(this.routes.settingMaintenance);
  }
}
