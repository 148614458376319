import { Routes } from '@angular/router';
import { maintenanceGuard } from './services/maintenance.guard';

export const routes: Routes = [
  {
    path: 'event/:id',
    canActivate: [maintenanceGuard],
    loadComponent: () => import('./pages/registration/registration.component').then(c => c.RegistrationComponent)
  },
  {
    path: 'training/:id',
    canActivate: [maintenanceGuard],
    loadComponent: () => import('./pages/registration/registration.component').then(c => c.RegistrationComponent)
  },
  {
    path: 'maintenance',
    loadComponent: () => import('./pages/maintenance/maintenance.component').then(c => c.MaintenanceComponent)
  }
];
